<template>
  <div class="head">
      <div v-if="props.brand_package && props.background" class="bg empty_bg" :style="'background-image: url(' + props.background + ')'"></div>

      <div class="pb grid_width bgbr">
        <UiBread
          :list="props.bread"
          :position="'left'"
          />
      </div>

      <div class="head_bg empty_bg">
        <div class="pb grid_width page_content">
          <div class="head_info">
            <div class="item_name">
              <NuxtLink v-if="props.link" :to="props.link" class="item_logo">
                <img :src="props.logo" class="logo_item" :alt="props.nmalt">
              </NuxtLink>

              <div class="item_info">
                <div class="name_bank" v-if="props.brand_name">
                  <NuxtLink v-if="props.brand_link" :to="props.brand_link">
                    {{ brand_name }}
                  </NuxtLink>
                </div>

                <h1 class="name">{{ nm }}</h1>

                <div class="slogan">
                  {{ slogan }}
                </div>

                <div class="rating_head" v-if="props.rate">
                  <span class="star_rate"></span>

                  <div class="value">
                    <span>{{ props.rate }}</span>
                    <span class="gr">/</span>
                    <span class="max gr">{{ props.rateof }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="actions">
              <template v-if="props.offical_user">
                <NuxtLink class="user_official" :to="props.offical_user.link">
                  <div class="avatar">
                    <img :src="props.offical_user.avatar_little" :alt="props.offical_user.name">
                  </div>

                  <div class="info">
                    <div class="name">{{ props.offical_user.name }}</div>
                    <div class="status">Official account</div>
                  </div>
                </NuxtLink>
              </template>

              <UiButton
                v-if="props.linkShop"
                :target="'_blank'"
                :ico="'icon-cart'"
                :to="props.linkShop"
                :name="$t('strain_view_buy_now_button')"
                type="red"
                tag="NuxtLink"
                />

              <UiButton
                :name="$t('universal_button_follow')"
                :cnt="props.cnt_followers"
                type="primary"
                v-if="!props.isfollow"
                @click.native="follow"
                />

              <UiButton
                :name="$t('universal_button_unfollow')"
                :cnt="props.cnt_followers"
                type=""
                v-if="props.isfollow"
                @click.native="unfollow"
                />
            </div>
          </div>

          <slot></slot>
        </div>
      </div>
  </div>
</template>

<script setup>
const emits = defineEmits(['follow', 'unfollow'])

const props = defineProps({
  bread: {
    type: Array,
    default: [],
  },
  id: {
    type: Number,
    default: null,
  },
  brand_package: {
    type: Number,
    default: null,
  },
  linkShop: {
    type: String,
    default: null,
  },
  background: {
    type: String,
    default: null,
  },
  cover: {
    type: String,
    default: null,
  },
  cover_mobile: {
    type: String,
    default: null,
  },
  nm: {
    type: String,
    default: null,
  },
  nmalt: {
    type: String,
    default: null,
  },
  slogan: {
    type: String,
    default: null,
  },
  logo: {
    type: String,
    default: null,
  },
  link: {
    type: String,
    default: null,
  },
  rate: {
    type: Number,
    default: null,
  },
  type: {
    type: String,
    default: null,
  },
  rateof: {
    type: Number,
    required: false,
    default: 10,
  },
  isfollow: {
    type: Boolean,
    default: null,
  },
  cnt_followers: {
    type: Number,
    default: null,
  },
  offical_user: {
    type: Object,
    default: null,
  },
  brand_name: {
    type: String,
    default: null,
  },
  brand_link: {
    type: String,
    default: null,
  }
})


const follow = () => {

  if(!useAuth().isAuth())
    return useAuth().goToLogin();

  emits('follow')
}

const unfollow = () => {

  if(!useAuth().isAuth())
    return useAuth().goToLogin();

  emits('unfollow')
}


</script>

<style scoped>
.pg .pc .pb {
  align-items: flex-end;
  display: flex;
  padding: 10px 0 8px 144px;
}

@media (max-width: 450px) {
  .pg .pc .pb {
    padding: 15px 0 60px;
  }
}

.head .head_bg {
    background-color: var(--un-background-color-gray);
}

.head .bgbr {
  min-height: 60px;
}

.head .head_bg .page_content {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.head .head_bg .head_info {
  display: flex;
  align-items: center;
  width: 100%;
}

.head .head_bg .item_name {
  display: flex;
  align-items: center;
  z-index: 12;
  position: relative;
}

.head .head_bg .item_name .item_logo {
  width: 130px;
  background-color: white;
  padding: 2px;
  border-radius: 200px;
  margin-right: 1rem;
  margin-top: -43px;
  border: 4px var(--un-element-color-gray) solid;
  box-shadow: 0px 0px 20px #00000024;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.head .bg {
  height: 230px;
  margin-bottom: -64px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
}

.head .head_bg .item_name .item_logo .logo_item {
  width: 100%;
  object-fit: contain;
  aspect-ratio: 1/1;
  border-radius: 100%;
}

.head .head_bg .item_name .item_info {
  padding: 15px 0;
}

.head .head_bg .item_name .item_info .name {
  margin-bottom: 6px;
  line-height: 1.3rem;
  margin-top: 0;
}

.head .head_bg .item_name .item_info .name_bank {
  margin-bottom: 8px;
}

.head .head_bg .item_name .item_info .rating_head {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 3px;
}

.head .head_bg .tabs_menu {
  margin: 0 auto;
  width: 100%;
}

.head .head_bg .tabs_menu,
.head .head_bg .touch_slider_content,
.head .head_bg .touch_slider_scroll {
  height: 45px !important;
}

.head .head_bg .tabs_menu .menu_item {
  padding: 10px 1.4em 0;
  height: 42px;
  margin-bottom: 0;
}

.tabs_menu .menu_item:hover, .tabs_menu .menu_item.active {
  border-bottom: 2px var(--un-primary-back-color) solid;
}

.head .head_bg .tabs_menu .menu_item div {
  font-size: 1rem;
  text-transform: inherit;
  color: gray!important;
}

.head .head_bg .tabs_menu .menu_item span {
  display: inline-block;
  min-height: auto;
  margin-left: 9px;
  min-width: auto;
  font-size: 1rem;
  text-transform: inherit;
  background-color: transparent!important;
  padding: 0!important;
  color: black!important;
}

.head .head_bg .actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

.head .head_bg .actions > * {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 13px;
}

.head .head_bg .actions .popup_more > * {
  padding: 5px 10px;
  display: block;
}

.head .head_bg .actions .dk {
  display: inherit;
}

.head .head_bg .actions .mb {
  display: none;
}

.head .touch_slider .shadow_right,
.head .touch_slider .shadow_left{
  top: 11px;
}
.head .touch_slider .shadow_left,
.head .touch_slider .shadow_right{
   top: -5px;
}
.head .touch_slider .shadow_left .shadow_inner,
.head .touch_slider .shadow_right .shadow_inner{
    background-color: #f7f7f7;
    border-radius: 0;
}
.head .touch_slider .shadow_left:hover .shadow_inner,
.head .touch_slider .shadow_right:hover .shadow_inner{
    background-color: #f7f7f7;
}

@media (max-width: 650px) {
  .head .bg {
    height: 180px;
    background-repeat: no-repeat;
    background-size: auto 140%;
    background-position-y: center;
    background-position-x: center;
  }
}

@container pb (max-width: 600px) {
  .head .head_bg .user_official {
    display: none;
  }

  .head .head_bg .head_info {
    flex-wrap: wrap;
  }

  .head .head_bg .item_name {
    width: 100%;
  }

  .head .head_bg .actions {
    margin-left: 140px;
  }
}

/* brand_cover  */
.brand_cover .bc {
    width: 100%;
    border-radius: 15px;
}

.brand_cover .bcm {
  display: none;
}

@container pb (max-width: 650px) {
  .brand_cover .bc {
      display: none;
  }

  .brand_cover .bcm {
    max-width: none;
    width: calc(100% + 4rem);
    display: block;
    margin-left: -2rem;
    margin-top: -2rem;
    margin-bottom: -20px;
  }
}

/* user_official */
.user_official {
    position: relative;
    display: inline-block;
    text-align: left;
    vertical-align: top;
    margin-right: 20px;
    height: 30px;
}

.user_official .avatar {
    width: 40px;
    position: relative;
    height: 40px;
}

.user_official .avatar img {
    border-radius: 50%;
    width: 100%;
    border: 2px white solid;
}

.user_official .info {
    width: calc(100% - 50px);
    margin-left: 10px;
    line-height: 1.1rem;
}

.user_official .user_range {
    float: left;
    font-size: 1.6rem;
    margin-right: 15px;
    position: absolute;
    left: 20px;
    bottom: 2px;
}

.user_official .info .name {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #494949;
}

.user_official .info .status {
    color: #9c9c9c;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>